import React from 'react'
import './PrivateRental.css'
import { Widget } from '@typeform/embed-react'

function PrivateRental() {
    return (
        <div style={{ width: '100%', minHeight: '85vh', backgroundColor: '#590080' }}>
            <Widget id="puLFduQv" className="my-form" style={{ width: '100%', minHeight: '85vh' }} />
        </div>
    )
}

export default PrivateRental